import React from 'react'
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../layouts/layout-fixed'

const NotFoundPage = () => (
  <Layout>
    <Helmet htmlAttributes={{ class:'notfoundpage fixed' }} />
    <SEO title="Page not found" />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
      className="fade-in"
        >
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
